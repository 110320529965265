<template>
  <app-dialog
    v-model="model"
    :submitting="status === 'pending'"
    :subtitle="subtitle"
    :title="title"
    :width="700"
    @submit="onSubmit"
  >
    <app-form-input-textarea
      v-model="comment"
      hide-label
      label="Raison"
      placeholder="Précisez la raison de votre refus si vous le souhaitez..."
    />

    <template #footer>
      <div class="grid place-content-end">
        <app-button color="danger" type="submit"> Refuser </app-button>
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import type { RegistrationRequestView } from "~/business-areas/registration-request/registration-request.model";

const properties = defineProps<{
  modelValue?: boolean;
  request: RegistrationRequestView;
}>();

const model = useVModel(properties, "modelValue");

const comment = ref("");

const title = computed(
  () => `Refuser la demande de ${properties.request.member.user.full_name}`,
);

const subtitle = computed(
  () =>
    `Un e-mail sera envoyé à ${properties.request.member.user.first_name} avec la raison du refus si vous la précisez.`,
);

const { close } = useDialog("RegistrationRequestRejectionDialog");

const { refresh, status } = useLazyFetch(
  `/api/organizations/${properties.request.organization_id}/requests/${properties.request.id}/reject`,
  {
    method: "POST",
    body: { comment },
    immediate: false,
    watch: false,
  },
);

const onSubmit = async () => {
  await refresh();
  close({ confirmed: true });
};
</script>
